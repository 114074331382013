"use client";

import styled from "styled-components";
import { includeSpacing } from "@boxt/design-system";

export const PhotosListContainer = styled.ul`
  ${includeSpacing({ mt: { sm: 4, md: 2, lg: 2 } })};
`;

export default PhotosListContainer;
