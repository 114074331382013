import useTranslation from "next-translate/useTranslation";
import { Grid, H5 } from "@boxt/design-system";

import Image from "@Components/Image";
import type { PhotoKey } from "@Components/pages/photos/PhotoUpload/types";
import { ReactComponent as RoundCrossSVG } from "@Images/icons/round-cross.svg";
import { ReactComponent as RoundTickSVG } from "@Images/icons/round-tick.svg";

import { ImageExampleContainer, ImageExamples, ImageExamplesRow, PhotoTextContainer } from "./styles";

export type Props = {
  photoKey: PhotoKey;
  i18nNamespace: string;
};

const photoWidth = 336;
const photoHeight = 380;

const PhotoExamples = ({ photoKey, i18nNamespace }: Props) => {
  const { t } = useTranslation(i18nNamespace);

  return (
    <Grid.Col as={ImageExamples} lg={8}>
      <Grid.Row as={ImageExamplesRow}>
        <Grid.Col sm={2} md={4} lg={8}>
          <ImageExampleContainer>
            <Image
              key={`${photoKey}-good`}
              src={`/_next-public/images/upload-examples/good-${photoKey}.jpg`}
              alt={`${t(`${photoKey}.title`)} ${t(`good-example`)}`}
              width={photoWidth}
              height={photoHeight}
              layout="responsive"
            />
          </ImageExampleContainer>
          <PhotoTextContainer $example="good">
            <RoundTickSVG />
            <H5>{t("good-example")}</H5>
          </PhotoTextContainer>
        </Grid.Col>
        <Grid.Col sm={2} md={4} lg={8}>
          <ImageExampleContainer>
            <Image
              key={`${photoKey}-bad`}
              src={`/_next-public/images/upload-examples/bad-${photoKey}.jpg`}
              alt={`${t(`${i18nNamespace}:${photoKey}.title`)} ${t(`bad-example`)}`}
              width={photoWidth}
              height={photoHeight}
              layout="responsive"
            />
          </ImageExampleContainer>
          <PhotoTextContainer $example="bad">
            <RoundCrossSVG />
            <H5>{t("bad-example")}</H5>
          </PhotoTextContainer>
        </Grid.Col>
      </Grid.Row>
    </Grid.Col>
  );
};

export default PhotoExamples;
