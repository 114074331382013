"use client";

import styled from "styled-components";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

export const Container = styled.div`
  ${includeSpacing({ mt: { sm: 4, md: 2, lg: 2 }, mb: 3 })};
  background-color: ${colors.white};
  border-radius: 6px;
  padding: 1.5rem 1rem;

  ${breakpoint("lg")} {
    padding: 1.5rem;
  }
`;

export const PhotoHeader = styled.div`
  ${includeSpacing({ mb: 3 })};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.platinum.light};
  padding-bottom: 1.25rem;
`;

export const HeaderIcon = styled.div<{ $isUploaded: boolean }>`
  width: 2.5rem;
  height: 2.5rem;
  ${includeSpacing({ mr: 2 })};
  color: ${({ $isUploaded }) => ($isUploaded ? colors.jade.normal : colors.coral.normal)};
`;

export const Span = styled.span`
  margin-left: 0.5rem;
  line-height: 1.5rem;
  font-size: ${rem(16)};
  font-weight: 400;
`;
