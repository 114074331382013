import { useCallback, useState } from "react";
import type { FileRejection } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { Button, Grid, Spacing } from "@boxt/design-system";

import { Flows } from "@Collections/photoFlows";
import type { PhotoPreviewT } from "@Components/pages/photos/PhotoUpload/types";
import { uploadPhotos } from "@Locators";
import { ReactComponent as CrossSVG } from "@Images/icons/cross.svg";
import { ReactComponent as UploadSVG } from "@Images/icons/upload.svg";

import { Container, Copy, ErrorMessage, LargeTitle, SmallTitle } from "./styles";

export type Props = {
  flow: Flows;
  onFileSelection: (files: PhotoPreviewT[]) => void;
  i18nNamespace: string;
};

const DropUpload = ({ flow, onFileSelection, i18nNamespace }: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const [fileErrors, setFileErrors] = useState<string[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length) {
        const errorCodes: Set<string> = new Set();

        rejectedFiles.forEach(({ errors }) => {
          errors.forEach(({ code }) => {
            errorCodes.add(code);
          });
        });

        setFileErrors(Array.from(errorCodes));
      }

      const files = acceptedFiles.map(
        (file: File): PhotoPreviewT =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
      );

      onFileSelection(files);
    },
    [onFileSelection],
  );

  const { getRootProps, getInputProps, isDragAccept, open, isDragReject } = useDropzone({
    accept: { "image/*": [] },
    maxSize: 1.5e7,
    noClick: true,
    noKeyboard: true,
    onDrop,
    maxFiles: 1,
    multiple: false,
  });

  const buttonTheme = flow === Flows.rental ? "coral" : "jade";

  return (
    <>
      <Container data-testid={uploadPhotos.dragZone} {...getRootProps({ isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <UploadSVG />
        <SmallTitle align="center" top={2} bottom={6}>
          {!isDragReject ? t("upload-files.title.small") : t("upload-files.title.error")}
        </SmallTitle>
        <LargeTitle align="center" top={2} bottom={2}>
          {!isDragReject ? t("upload-files.title.large") : t("upload-files.title.error")}
        </LargeTitle>
        <Trans
          i18nKey={!isDragReject ? `${i18nNamespace}:upload-files.help` : `${i18nNamespace}:upload-files.help-error`}
          components={{ copy: <Copy bottom={5} align="center" />, s: <strong /> }}
        />
        <Grid.Col sm={3} md={5} lg={11}>
          <Button isFullWidth boxtTheme={buttonTheme} onClick={open} testId={uploadPhotos.selectPhoto}>
            {t("upload-files.select-photo")}
          </Button>
        </Grid.Col>
      </Container>
      {!!fileErrors.length && (
        <Spacing mt={1}>
          {fileErrors.map((fileError) => (
            <ErrorMessage boxtTheme="error" key={fileError}>
              <CrossSVG />
              {t(`upload-files.errors.${fileError}`)}
            </ErrorMessage>
          ))}
        </Spacing>
      )}
    </>
  );
};

export default DropUpload;
