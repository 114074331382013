"use client";

import styled from "styled-components";
import { breakpoint, H3, includeSpacing, rem } from "@boxt/design-system";

import { ReactComponent as PhotosDone } from "@Images/phone.svg";

export const Icon = styled.div`
  width: 100%;
  height: 100%;
  ${includeSpacing({ mb: 1 })};
  svg {
    margin: 0 auto;
    display: block;
  }
`;

export const PhotosDoneSVG = styled(PhotosDone)`
  max-width: ${rem(200)};
  margin: 0 auto;

  ${breakpoint("md")} {
    max-width: ${rem(300)};
  }

  ${breakpoint("lg")} {
    max-width: 100%;
    margin: 0;
  }
`;

export const PhotoRow = styled.div`
  text-align: center;
`;

export const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-content: center;
  max-height: ${rem(600)};
  ${breakpoint("lg")} {
    grid-template-columns: 2fr 1fr;
  }
`;

export const Wrapper = styled.div`
  max-width: ${rem(550)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  ${breakpoint("lg")} {
    display: block;
  }
`;

export const Title = styled(H3)`
  max-width: ${rem(300)};
  ${breakpoint("md")} {
    max-width: unset;
  }
`;

export const Row = styled.div`
  ${breakpoint("lg")} {
    max-height: ${rem(500)};
  }
`;

export const OrdersRow = styled.div`
  ${breakpoint("lg")} {
    min-height: ${rem(500)};
  }
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  ${includeSpacing({ mb: 5 })};

  ${breakpoint("lg")} {
    text-align: left;
  }
`;
