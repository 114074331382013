import getConfig from "next/config";
import type { ImageLoaderProps } from "next/image";
import ImgixClient from "@imgix/js-core";

export type ImgixParams = {
  imgixParams?: {
    w?: string;
    auto?: string;
    q?: string;
  };
};

function imgixLoader({
  src,
  width,
  quality = 75,
  imgixParams = {
    w: String(width),
    auto: "format",
    q: String(quality),
  },
}: ImageLoaderProps & ImgixParams): string {
  const { publicRuntimeConfig } = getConfig();

  // always resize butter cms images using imgix
  if (src.includes("cdn.buttercms.com")) {
    const url = new URL(src);
    const imgixButterCmsClient = new ImgixClient({
      domain: publicRuntimeConfig.IMGIX_BCMS_DOMAIN,
      includeLibraryParam: false,
    });
    return imgixButterCmsClient.buildURL(url.pathname + url.search, imgixParams);
  }

  if (src.includes("imgix.net")) {
    const url = new URL(src);
    const { origin, pathname } = url;
    const searchParams = new URLSearchParams(imgixParams);
    return `${origin}${pathname}?${searchParams.toString()}`;
  }
  if (src.startsWith("@s3")) {
    const cleanedSrc = src.replace("@s3/", "");
    const imgixClient = new ImgixClient({
      domain: publicRuntimeConfig.IMGIX_S3_DOMAIN,
      includeLibraryParam: false,
    });
    return imgixClient.buildURL(cleanedSrc, imgixParams);
  }

  if (src.startsWith("/_next-public/images")) {
    // only for local images
    if (publicRuntimeConfig.LOCAL_IMAGES_PROCESSOR === "local") {
      return `/_next/image?url=${encodeURIComponent(src)}&w=${width}&q=${quality}`;
    }

    if (publicRuntimeConfig.LOCAL_IMAGES_PROCESSOR === "imgix") {
      const imgixClient = new ImgixClient({
        domain: publicRuntimeConfig.IMGIX_DOMAIN,
        includeLibraryParam: false,
      });
      return imgixClient.buildURL(src, imgixParams);
    }
  }

  // fallback - except butter cms all external images will be loaded in original size
  return src;
}

export default imgixLoader;
