import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import type { Products, StepItem } from "@boxt/design-system";

import { BOXT_CUSTOMER_URLS } from "@Collections/routes";
import { actions, category, ReactGAEvent } from "@Lib/googleAnalytics";

const useStepNav = () => {
  const { t } = useTranslation("common");
  const router = useRouter();

  const handleChangeRoute = (href: string) => router?.push(href);
  const handleLogoClick = () => {
    ReactGAEvent({
      category: category.repair,
      action: actions.home,
    });
  };

  const decorateSteps = (
    product: Products,
    initialSteps: Omit<StepItem, "label" | "stepCounter">[],
    screeningId?: string,
  ): StepItem[] => {
    return initialSteps.map((step) => {
      const decoratedValues = {
        label: t(`step-nav.${product}.${step.name}`),
        stepCounter: t("common:step-nav-info", {
          step: step.stepNo,
          total: initialSteps.length,
        }),
      };
      if (step?.href && screeningId) {
        const href = step.href.replace("[screeningId]", screeningId);
        return {
          ...step,
          ...decoratedValues,
          href,
        };
      }
      return {
        ...step,
        ...decoratedValues,
      };
    });
  };
  const navLabelProps = {
    logoHref: BOXT_CUSTOMER_URLS.HOME,
    onLogoClick: handleLogoClick,
  };
  const stepNavProps = {
    ...navLabelProps,
    handleChangeRoute: handleChangeRoute,
  };

  return { decorateSteps, navLabelProps, stepNavProps };
};

export default useStepNav;
