import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { Grid, H3, Paragraph } from "@boxt/design-system";
import { Spacing } from "@boxt/design-system";

import { Flows } from "@Collections/photoFlows";
import { ReactComponent as PhotosDone } from "@Images/phone.svg";

import { Icon } from "./styles";

export type Props = {
  flow: string;
  i18nNamespace: string;
};

const PhotoHero = ({ flow, i18nNamespace }: Props) => {
  const isRepairs = flow === Flows.repairs;
  const isHomecover = flow === Flows.homecover;
  const isCallouts = flow === Flows.callouts;
  const { t } = useTranslation(i18nNamespace);

  return (
    <Grid.Row as={Spacing} mb={4}>
      <Grid.Col md={0} lg={0}>
        <Icon>
          <PhotosDone />
        </Icon>
      </Grid.Col>
      <Grid.Col md={{ span: 6, offset: 1 }} lg={{ span: 7, offset: 3 }}>
        <H3 top={{ sm: 0, lg: 6 }} bottom={5}>
          {t("title")}
        </H3>
        {!isHomecover && (
          <Trans
            i18nKey={`${i18nNamespace}:sub-title`}
            components={{ p: <Paragraph bottom={3} size="large" />, s: <strong /> }}
          />
        )}
        {!isRepairs && !isCallouts && <Paragraph>{t("explainer-content")}</Paragraph>}
      </Grid.Col>
      <Grid.Col sm={0} md={0} lg={{ span: 3 }}>
        <PhotosDone />
      </Grid.Col>
    </Grid.Row>
  );
};

export default PhotoHero;
