import useTranslation from "next-translate/useTranslation";
import { Grid, H3, Paragraph } from "@boxt/design-system";

import { ReactComponent as PhotosDoneSVG } from "@Images/icons/photos-done.svg";

import { Icon } from "../styles";

const OrdersNoLongerRequired = () => {
  const { t } = useTranslation("photos/homecover/no-longer-required");

  return (
    <>
      <Grid.Row>
        <Grid.Col md={0} lg={0}>
          <Icon>
            <PhotosDoneSVG />
          </Icon>
        </Grid.Col>
        <Grid.Col md={{ span: 6, offset: 1 }} lg={{ span: 7, offset: 3 }}>
          <H3 bottom={4}>{t("title")}</H3>
          <Paragraph bottom={7} size="large">
            {t("sub-title")}
          </Paragraph>
        </Grid.Col>
        <Grid.Col sm={0} md={0} lg={3}>
          <PhotosDoneSVG />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={0} md={{ span: 6, offset: 1 }} lg={{ span: 10, offset: 3 }} />
      </Grid.Row>
    </>
  );
};

export default OrdersNoLongerRequired;
