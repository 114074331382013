const checkoutLocator = {
  "order-summary": {
    next: "checkout-order-summary-next-button",
  },
  date: {
    preferredTime: "checkout-date-preferred-time--", // data-test=checkout-date-preferredTime${option}
    next: "checkout-date-next-button",
  },
  details: {
    addressLine1: "checkout-details-address-line-1",
    addressLine2: "checkout-details-address-line-2",
    addressLine3: "checkout-details-address-line-3",
    city: "checkout-details-city",
    county: "checkout-details-county",
    note: "checkout-details-note",
    firstName: "checkout-details-first-name",
    title: "checkout-details-title",
    surname: "checkout-details-surname",
    email: "checkout-details-email",
    phone: "checkout-details-phone",
    howFirstHearAboutBoxt: "checkout-details-how-first-hear-about-boxt",
    postcodeInput: "checkout-details-postcode-input",
    postcodeButton: "checkout-details-postcode-button",
    preferredPaymentOptions: "checkout-details-preferred-payment-options",
    next: "checkout-details-next-button",
    postcode: "checkout-details-postcode",
    issueWithAddress: "checkout-details-issue-with-address",
    dob: "checkout-details-date-of-birth",
    acceptPrivacyPolicy: "checkout-details-accept-privacy-policy",
    acceptTermsAndConditions: "checkout-details-accept-terms-and-conditions",
  },
  payment: {
    terms: "checkout-payment-terms-checkbox",
    emails: "checkout-payment-emails-checkbox",
    book: "checkout-payment-book-button",
  },
  installer: {
    next: "checkout-installer-next-button",
  },
  "installation-address": {
    next: "checkout-installation-address-next-button",
    addAddress: "checkout-installation-add-address-button",
  },
  "address-history": {
    next: "checkout-address-history-next-button",
    years: "checkout-address-history-years",
    months: "checkout-address-history-months",
  },
  "financial-information": {
    next: "checkout-financial-information-next-button",
    employmentStatus: "checkout-financial-information-employment-status",
    financialSituation: "checkout-financial-information-financial-situation",
    takeHomePay: "checkout-financial-information-take-home-pay",
    householdIncome: "checkout-financial-information-household-income",
    mortgagePayments: "checkout-financial-information-mortgage-payments",
    otherExpenses: "checkout-financial-information-other-expenses",
    numberOfAdults: "checkout-financial-information-number-of-adults",
    numberOfChildren: "checkout-financial-information-number-of-children",
  },
  "next-steps": {
    next: "checkout-rental-next-steps",
  },
  "hire-agreement": {
    next: "checkout-hire-agreement-next-button",
    checkbox: "checkout-hire-agreement-checkbox",
  },
  "pre-contract-documents": {
    next: "checkout-pre-contract-documents-next-button",
    checkbox: "checkout-pre-contract-documents-checkbox",
  },
  novuna: {
    plan: "finance-plans--",
    slider: "slider",
    promoCode: "finance-got-a-promo-code",
  },
};

export default checkoutLocator;
