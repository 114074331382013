import type { NextRouter } from "next/router";
import { isEmpty } from "lodash";

const getPathAndSearchParamsFromRouter = (router: NextRouter | null): { plainUrl?: string; searchParams: string } => {
  const query = router?.query;
  const pathname = router?.pathname;

  let plainUrl = pathname;
  const searchParams = new URLSearchParams(query as Record<string, string>);

  if (query) {
    Object.entries(query).forEach(([key, value]) => {
      if (plainUrl?.includes(key)) {
        plainUrl = plainUrl.replace(`[${key}]`, value as string);
        searchParams.delete(key);
      }
    });
  }

  return { plainUrl, searchParams: isEmpty(searchParams.toString()) ? "" : `?${searchParams.toString()}` };
};

export default getPathAndSearchParamsFromRouter;
