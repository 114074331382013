import Link from "next/link";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { Button, Grid, Paragraph, Spacing } from "@boxt/design-system";

import { Container, PhotosDoneSVG, Row, Title, Wrapper } from "./styles";

type Props = { url: string | undefined; i18nNamespace: string };

const Common = ({ url, i18nNamespace }: Props) => {
  const { t } = useTranslation(i18nNamespace);

  return (
    <Grid.Row as={Row}>
      <Grid.Col md={{ span: 6, offset: 1 }} lg={{ span: 10, offset: 3 }} as={Container}>
        <Wrapper>
          <Title align={{ sm: "center", md: "left" }} bottom={3}>
            {t("title")}
          </Title>
          <Trans
            i18nKey={`${i18nNamespace}:text`}
            components={{ p: <Paragraph align={{ sm: "center", lg: "left" }} bottom={6} />, s: <strong /> }}
          />
          {Boolean(url) && (
            <>
              <Link href={url as string} passHref legacyBehavior>
                <Button boxtTheme="jade" size="medium" data-testid="cta-button">
                  {t("button")}
                </Button>
              </Link>
              <Spacing mb={4} />
            </>
          )}
        </Wrapper>
        <PhotosDoneSVG />
      </Grid.Col>
    </Grid.Row>
  );
};

export default Common;
