import useTranslation from "next-translate/useTranslation";
import { H4 } from "@boxt/design-system";

import getI18nNamespace from "@Helpers/photos/getI18nNamespace";
import type { Flows } from "@Collections/photoFlows";
import type { Products } from "@Collections/products";
import type { Photo } from "@DataAccess/local/customers/orders/photos";
import { getPhotoIcon } from "@Components/pages/photos/PhotosList/components/Item/helpers";
import PhotoUploadStatus from "@Components/pages/photos/PhotoUpload/constants";

import PhotoDetailsAndUpload from "./components/PhotoDetailsAndUpload";
import { Container, HeaderIcon, PhotoHeader, Span } from "./styles";
import type { PhotoPreviewT } from "./types";

export type Props = {
  hasFailedUploading: boolean;
  isUploading: boolean;
  onFileSelection: (files: PhotoPreviewT[]) => void;
  onRemovePreview: () => void;
  onRetryUpload: () => void;
  photo: Photo;
  flow: Flows;
  previewPhotos: PhotoPreviewT[];
  productType: Products;
};

const PhotoUpload = ({
  hasFailedUploading,
  isUploading,
  onFileSelection,
  onRemovePreview,
  onRetryUpload,
  photo,
  flow,
  previewPhotos,
  productType,
}: Props) => {
  const nameSpace = getI18nNamespace({ flow, productType }).photos;
  const { t } = useTranslation(nameSpace);
  const { status, key, photoKey } = photo;
  const isUploaded = status === PhotoUploadStatus.Uploaded;
  const isOptional = photo.optional;
  const PhotoIcon = getPhotoIcon(photo.status);
  return (
    <Container>
      <PhotoHeader>
        <HeaderIcon $isUploaded={isUploaded}>
          <PhotoIcon />
        </HeaderIcon>
        <H4>
          {t(`${key}.title`, { radiatorNumber: photoKey.split("-")[1] })}
          {isOptional && <Span>{t("optional-photo")}</Span>}
        </H4>
      </PhotoHeader>
      <PhotoDetailsAndUpload
        flow={flow}
        i18nNamespace={nameSpace}
        photoKey={key}
        previewPhotos={previewPhotos}
        onFileSelection={onFileSelection}
        onRemovePreview={onRemovePreview}
        onRetryUpload={onRetryUpload}
        isUploading={isUploading}
        hasFailedUploading={hasFailedUploading}
      />
    </Container>
  );
};

export default PhotoUpload;
