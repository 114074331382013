import useTranslation from "next-translate/useTranslation";
import { BulletPoints, Grid, H3, H4, H5, Paragraph } from "@boxt/design-system";

import type { Flows } from "@Collections/photoFlows";
import { ReactComponent as BulletSVG } from "@Images/icons/bullet.svg";

import { Icon, OrdersRow, PhotosDoneSVG } from "./styles";

type WhatNext = {
  item: string;
  key: string;
};

type Props = {
  flow: Flows;
};

const PhotosCompletedExtendedCopy = ({ flow }: Props) => {
  const { t } = useTranslation(`photos/${flow}/completed`);
  const whatNext: Object = t("what-next", {}, { returnObjects: true });

  return (
    <>
      <Grid.Row as={OrdersRow}>
        <Grid.Col md={0}>
          <Icon>
            <PhotosDoneSVG />
          </Icon>
        </Grid.Col>
        <Grid.Col md={{ span: 6, offset: 1 }} lg={{ span: 7, offset: 3 }}>
          <H3 bottom={4}>{t("title")}</H3>
          <Paragraph bottom={7} size="large">
            {t("sub-title")}
          </Paragraph>
          <H4 bottom={3}>{t("what-next-title")}</H4>
          <Paragraph bottom={6}>{t("what-next-copy")}</Paragraph>
          {Array.isArray(whatNext) && (
            <>
              <H5 bottom={3}>{t("prepare-installation")}</H5>
              <BulletPoints items={whatNext as WhatNext[]} icon={<BulletSVG />} />
            </>
          )}
        </Grid.Col>
        <Grid.Col sm={0} lg={3}>
          <PhotosDoneSVG />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={{ span: 6, offset: 1 }} lg={{ span: 10, offset: 3 }} />
      </Grid.Row>
    </>
  );
};

export default PhotosCompletedExtendedCopy;
