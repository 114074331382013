const uploadPhotos = {
  uploadItem: "upload-photos-item-button--", // data-test=`upload-photos-button-${value}`
  selectPhoto: "upload-photos-select-button",
  dragZone: "upload-photos-drag-zone",
  backToPhotoTask: "upload-photos-back-to-photo-task-button",
  uploadAction: "upload-photos-action-button", // can be upload || upload and finish
  finish: "finish-button",
};

export default uploadPhotos;
